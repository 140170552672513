import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Layout from "components/Layout";
import JobCard from "components/JobCard";

const Section = styled("div")`
  margin-bottom: 10rem;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 3rem;
    text-align: center;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.jobs {
    h2 {
      text-align: center;
      padding-top: 1rem;
      @media (max-width: ${dimensions.maxwidthTablet}px) {
        padding-top: 0;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: ${dimensions.maxwidthTablet}px) {
      padding: 0 1rem 2rem;
    }
    @media (max-width: ${dimensions.maxwidthMobile}px) {
      padding: 0 0 2rem;
      background: transparent;
    }
  }
`;

const JobGrid = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const RenderBody = ({ jobs, meta }) => (
  <>
    <Helmet
      title={meta.title}
      titleTemplate={meta.title}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Section className="jobs">
      <h1>{jobs.length === 0 ? "No " : ""}Current Job Openings</h1>
      <JobGrid>
        {jobs.map((job, i) => (
          <JobCard
            key={i}
            title={job.node.job_title}
            date={job.node.job_date}
            description={job.node.job_preview_description}
            uid={job.node._meta.uid}
          />
        ))}
      </JobGrid>
    </Section>
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata;
  const jobs = data.prismic.allJobs.edges;

  if (!jobs) return null;

  return (
    <Layout>
      <RenderBody jobs={jobs} meta={meta} />
    </Layout>
  );
};

RenderBody.propTypes = {
  meta: PropTypes.object.isRequired,
  jobs: PropTypes.array.isRequired,
};

export const query = graphql`
  {
    prismic {
      allJobs(sortBy: job_date_DESC) {
        edges {
          node {
            job_title
            job_date
            job_preview_description
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
