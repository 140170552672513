import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";

const JobCardContainer = styled(Link)`
  border: 1px solid #eaeaea;
  margin-bottom: 2rem;
  padding: 2rem 0;
  border-radius: 4px;
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
  transition: all 150ms ease-in-out;
  position: relative;
  align-content: flex-start;
  width: 100%;
  &:hover {
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
    transition: all 150ms ease-in-out;
    cursor: pointer;

    .JobCardAction {
      transition: all 150ms ease-in-out;

      svg {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
        path {
          fill: ${colors.accent};
        }
      }
    }
  }
`;

const JobTitle = styled("h3")`
  margin: 0;
  width: calc(100% - 2rem);
  padding: 0 2rem;
  @media (max-width: ${dimensions.maxwidthTablet}px) {
    font-size: 1.6rem;
  }
`;

const JobCardAction = styled("div")`
  font-weight: 600;
  text-decoration: none;
  color: currentColor;
  transition: all 150ms ease-in-out;
  position: absolute;
  right: 1rem;

  svg {
    margin-left: 1rem;
    transform: translateX(-8px);
    display: inline-block;
    transition: transform 400ms ease-in-out;
    width: 1rem;
  }
`;

const JobCard = ({ title, uid }) => (
  <JobCardContainer className="BlogJobCard" to={`/${uid}`}>
    <JobTitle>{title[0].text}</JobTitle>
    <JobCardAction className="JobCardAction">
      <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
        <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"></path>
      </svg>
    </JobCardAction>
  </JobCardContainer>
);

export default JobCard;

JobCard.propTypes = {
  title: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
};
